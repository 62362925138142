import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Login from './Dashboard/login';

const BannerTwo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showLogin, setShowLogin] = useState(false);
  const [route, setRoute] = useState('');
  const [webpSupported, setWebpSupported] = useState(null);

  // Debounce function to limit resize event frequency
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Handle window resize with debouncing
  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 200);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check WebP support with debugging
  useEffect(() => {
    const supportsWebP = () => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(img.width > 0 && img.height > 0);
        img.onerror = () => resolve(false);
        img.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73';
      });
    };

    supportsWebP().then((supported) => {
      setWebpSupported(supported);
      console.log('WebP supported:', supported); // Debug WebP support
    });
  }, []);

  // Memoized dynamic styles
  const dynamicStyles = useMemo(
    () => ({
      title: {
        fontSize: windowWidth <= 768 ? '3.5rem' : '85px',
        fontFamily: "'Poppins', sans-serif",
        marginLeft: windowWidth <= 768 ? 'auto' : '10vh',
        marginTop: windowWidth <= 768 ? '5vh' : '10vh',
        whiteSpace: windowWidth <= 768 ? 'nowrap' : 'normal',
        overflow: windowWidth <= 768 ? 'hidden' : 'visible',
        textOverflow: windowWidth <= 768 ? 'ellipsis' : 'clip',
        textAlign: windowWidth <= 768 ? 'center' : 'left',
      },
      content: {
        fontSize: windowWidth <= 768 ? '1.25rem' : '40px',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '500',
        marginLeft: windowWidth <= 768 ? 'auto' : '10vh',
        marginTop: windowWidth <= 768 ? '-1.5rem' : '0',
        textAlign: windowWidth <= 768 ? 'center' : 'left',
      },
      btnContainer: {
        textAlign: windowWidth <= 768 ? 'center' : 'left',
        display: windowWidth <= 768 ? 'block' : 'inline-block',
        marginLeft: windowWidth <= 768 ? 'auto' : 'auto',
      },
      btn: {
        marginLeft: windowWidth <= 768 ? '40px' : '5rem',
        marginRight: windowWidth <= 768 ? '40px' : '1vh',
        marginBottom: windowWidth <= 768 ? '1vh' : '1vh',
        display: windowWidth <= 768 ? 'block' : 'inline-block',
      },
      secondBtn: {
        marginLeft: windowWidth <= 768 ? '40px' : '1rem',
        marginRight: windowWidth <= 768 ? '40px' : '1rem',
        marginBottom: windowWidth <= 768 ? '1vh' : '1vh',
        display: windowWidth <= 768 ? 'block' : 'inline-block',
      },
      imageSmall: {
        height: windowWidth <= 768 ? '15vh' : '25vh',
        width: 'auto',
        objectFit: 'contain',
        marginLeft: windowWidth <= 768 ? '3rem' : '0',
        marginBottom: windowWidth <= 768 ? '0' : '0',
      },
      imageLarge: {
        height: windowWidth <= 768 ? '50vh' : '77vh', // Adjusted for visibility
        width: 'auto',
        objectFit: 'contain',
        marginLeft: windowWidth <= 768 ? '0' : '0',
        marginTop: windowWidth <= 768 ? '0' : '0',
      },
      bg: {
        height: windowWidth <= 768 ? '50vh' : '77vh', // Adjusted for visibility
        width: 'auto',
        objectFit: 'contain',
        marginLeft: windowWidth <= 768 ? '0' : '0',
        marginTop: windowWidth <= 768 ? '0' : '0',
      },
      img5: {
        height: windowWidth <= 768 ? '7vh' : '10vh', // Adjusted for visibility
        width: 'auto',
        objectFit: 'contain',
        marginRight: windowWidth <= 768 ? '0' : '0',
        marginTop: windowWidth <= 768 ? '0' : '0',
      },
      Arrow: {
        height: windowWidth <= 768 ? '5vh' : '7vh', // Adjusted for visibility
        width: 'auto',
        objectFit: 'contain',
        marginLeft: windowWidth <= 768 ? '0' : '0',
        marginTop: windowWidth <= 768 ? '0' : '0',
      },
    }),
    [windowWidth]
  );

  const backgroundImageUrl =
    webpSupported === null || !webpSupported
      ? 'url(/assets/img/bg/8.png)'
      : 'url(/assets/img/bg/8.webp)';

  const handleRouteChange = (newRoute) => {
    setRoute(newRoute);
    setShowLogin(true);
  };

  const openAppStore = (appType) => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const iosPrefix = 'https://apps.apple.com/in/app/';
    const androidPrefix = 'https://play.google.com/store/apps/details?id=';

    if (appType === 'hotel') {
      if (isAndroid) {
        window.open(`${androidPrefix}com.shawezz.joyStayHotel&pcampaignid=web_share`, '_blank');
      } else {
        window.open(`${iosPrefix}joy-stays-hotels/id6475736341`, '_blank');
      }
    } else if (appType === 'investor') {
      if (isAndroid) {
        window.open(`${androidPrefix}com.shawezz.joyStayInvestor&pcampaignid=web_share`, '_blank');
      } else {
        window.open(`${iosPrefix}joy-stays-investor/id6475810491`, '_blank');
      }
    }
  };

  return (
    <>
      <Helmet>
        {/* Preload critical images */}
        <link
          rel="preload"
          as="image"
          href={webpSupported ? '/assets/img/banner-2/1.webp' : '/assets/img/banner-2/1.png'}
          type={webpSupported ? 'image/webp' : 'image/png'}
        />
        <link
          rel="preload"
          as="image"
          href={webpSupported ? '/assets/img/bg/8.webp' : '/assets/img/bg/8.png'}
          type={webpSupported ? 'image/webp' : 'image/png'}
        />
      </Helmet>
      {showLogin ? (
        <Login route={route} />
      ) : (
        <div
          className="banner-area bg-relative banner-area-2 bg-cover"
          style={{ backgroundImage: backgroundImageUrl }}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 col-md-9"
                data-aos="fade-left"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <div className="banner-thumb-2 mt-4 mt-lg-0">
                  {/* Background Image */}
                  <img
                    className="banner-animate-img banner-animate-img-4"
                    src={webpSupported ? '/assets/img/banner-2/3.webp' : '/assets/img/banner-2/3.png'}
                    alt="Decorative background"
                    style={dynamicStyles.bg}
                    loading="lazy"
                    width="1200"
                    height="800"
                    onError={() => console.error('Failed to load banner-2/3 image')}
                  />
                  <div className="main-img-wrap">
                    {/* Arrow Image */}
                    <img
                      className="banner-animate-img banner-animate-img-1 left_image_bounce"
                      src={webpSupported ? '/assets/img/banner-2/4.webp' : '/assets/img/banner-2/4.png'}
                      alt="Arrow icon"
                      style={dynamicStyles.Arrow}
                      loading="lazy"
                      width="100"
                      height="100"
                      onError={() => console.error('Failed to load banner-2/4 image')}
                    />
                    {/* Small Decorative Image */}
                    <img
                      className="banner-animate-img banner-animate-img-2 left_image_bounce"
                      src={webpSupported ? '/assets/img/banner-2/5.webp' : '/assets/img/banner-2/5.png'}
                      alt="Small decorative image"
                      style={dynamicStyles.img5}
                      loading="lazy"
                      width="100"
                      height="100"
                      onError={() => console.error('Failed to load banner-2/5 image')}
                    />
                    {/* Medium Decorative Image */}
                    <img
                      className="banner-animate-img banner-animate-img-3 top_image_bounce"
                      src={webpSupported ? '/assets/img/banner-2/2.webp' : '/assets/img/banner-2/2.png'}
                      alt="Medium decorative image"
                      style={dynamicStyles.imageSmall}
                      loading="lazy"
                      width="200"
                      height="200"
                      onError={() => console.error('Failed to load banner-2/2 image')}
                    />
                    {/* Main Banner Image */}
                    <img
                      className="main-img"
                      src={webpSupported ? '/assets/img/banner-2/1.webp' : '/assets/img/banner-2/1.png'}
                      alt="Main banner image"
                      style={dynamicStyles.imageLarge}
                      loading="eager"
                      fetchpriority="high"
                      width="1200"
                      height="800"
                      onError={() => console.error('Failed to load banner-2/1 image')}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="banner-inner pe-xl-5">
                  <h1
                    className="title"
                    data-aos="fade-right"
                    data-aos-delay="200"
                    data-aos-duration="1500"
                    style={dynamicStyles.title}
                  >
                    <span>JOY </span>STAYS{' '}
                    <p style={{ fontSize: '20px' }}>A Unit of JOX Private Limited</p>
                  </h1>
                  <h4
                    className="content pe-xl-5"
                    data-aos="fade-right"
                    data-aos-delay="250"
                    data-aos-duration="1500"
                    style={dynamicStyles.content}
                  >
                    India's #1 Hotel Trading Platform
                  </h4>
                  <div style={dynamicStyles.btnContainer}>
                    <button
                      className="btn btn-border-base"
                      data-aos="fade-right"
                      data-aos-delay="300"
                      data-aos-duration="1500"
                      onClick={() => openAppStore('hotel')}
                      style={dynamicStyles.btn}
                    >
                      Hotel
                    </button>
                    <button
                      className="btn btn-border-base"
                      data-aos="fade-right"
                      data-aos-delay="1000"
                      data-aos-duration="1500"
                      onClick={() => openAppStore('investor')}
                      style={dynamicStyles.secondBtn}
                    >
                      Investor
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerTwo;