import React from "react";

const WorkProcessTwo = () => {
  return (
    <>
      {/*==================== Work Process Two start ====================*/}
      <div className="work-process-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Hotel App</h6>
            <h2 className="title">
              India's <span>Premier</span> Hotel Trading Platform
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    src="/assets/img/icon/1H.png" // Absolute path
                    alt="Hotel App Step 1" // Descriptive alt text
                    loading="lazy" // Lazy load
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    src="/assets/img/icon/2h.png"
                    alt="Hotel App Step 2"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    src="/assets/img/icon/3h.png"
                    alt="Hotel App Step 3"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    src="/assets/img/icon/4h.png"
                    alt="Hotel App Step 4"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process Two end ====================*/}
      {/*==================== Work Process Three start ====================*/}
      <div className="work-process-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Investor App</h6>
            <h2 className="title">
              India's <span>#1</span> Hotel Investing Platform
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    src="/assets/img/icon/1I.png"
                    alt="Investor App Step 1"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    src="/assets/img/icon/2i.png"
                    alt="Investor App Step 2"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    src="/assets/img/icon/3i.png"
                    alt="Investor App Step 3"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    src="/assets/img/icon/4i.png"
                    alt="Investor App Step 4"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default WorkProcessTwo;