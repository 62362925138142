import React, { useState, useEffect, useMemo } from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaTwo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Debounce function to limit resize event frequency
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Handle window resize with debouncing
  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 200);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Memoized dynamic styles
  const dynamicStyles = useMemo(
    () => ({
      imageLarge: {
        height: windowWidth <= 768 ? "auto" : "77vh",
        width: "auto",
        objectFit: "contain", // Corrected from 'resize'
        marginLeft: windowWidth <= 768 ? "-1rem" : "auto",
        marginTop: windowWidth <= 768 ? "-50%" : "auto",
      },
    }),
    [windowWidth]
  );

  return (
    <div className="about-area pd-top-120 pd-bottom-120" style={{ fontFamily: "'Poppins', sans-serif" }}>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 mb-4 mb-lg-0"
            data-aos="fade-right"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            <div className="about-thumb-inner">
              <img
                className="main-img"
                src="/assets/img/about/33.png" // Absolute path
                alt="About JoyStays" // More descriptive alt text
                style={dynamicStyles.imageLarge}
                loading="lazy" // Lazy load for performance
              />
            </div>
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-left"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            <div className="section-title mb-0">
              <h6 className="sub-title">Features</h6>
              <h2 className="title">JOYSTAYS - The Premier Hotel Trading App in India</h2>
              <p className="content mb-4">Why Choose Us?</p>
              <div className="row">
                <div className="col-md-6">
                  <ul className="single-list-inner style-check style-heading style-check mb-3">
                    <li>
                      <FaCheckCircle /> Transparent & Simple
                    </li>
                    <li>
                      <FaCheckCircle /> Easy & Quick Buybacks
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="single-list-inner style-check style-heading style-check mb-3">
                    <li>
                      <FaCheckCircle /> Realtime Portfolio
                    </li>
                    <li>
                      <FaCheckCircle /> Maximise Profits
                    </li>
                  </ul>
                </div>
              </div>
              <p className="content">
                Joy Stays is not just a platform; it's a mission. Our
                enthusiastic and dynamic team is dedicated to creating value
                for hotel partners, enhancing facilities, and delivering
                delightful experiences to guests nationwide. Through strategic
                expansion plans, we aim to redefine the hospitality landscape,
                one room at a time.
              </p>
              <Link className="btn btn-border-base" to="/about">
                About Us <FaPlus />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutAreaTwo;